
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { SessionProvider } from "next-auth/react";
import { Analytics } from "@vercel/analytics/react";
import "../styles/globals.css";
import type { AppProps } from "next/app";
import { QueryClientProvider } from "@tanstack/react-query";
import { Hydrate } from "@tanstack/react-query";
import queryClient from "../lib/clients/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "@reach/dialog/styles.css";
import { Paytone_One, Outfit } from "next/font/google";
import clsx from "clsx";

const PaytoneOne = Paytone_One({
  subsets: ["latin"],
  weight: ["400"],
  variable: "--font-paytone-one",
});
const OutfitFont = Outfit({
  subsets: ["latin"],
  variable: "--font-outfit",
  weight: "variable",
});

const __Page_Next_Translate__ = function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <SessionProvider session={session} refetchInterval={5 * 60}>
          <div
            className={clsx(
              PaytoneOne.variable,
              OutfitFont.variable,
              "font-sans"
            )}
          >
            <Component {...pageProps} />
          </div>
          <Analytics />
        </SessionProvider>
      </Hydrate>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  